import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageLollipops = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/lollipops_large.jpg';

const RecipePage = () => (
    <Layout metaTitle="Steak Cut Bacon Lollipops Recipe"
        metaDescription="Slow cured thick-cut Wright® Brand Bacon skewered & grilled to crispy tender perfection, then glazed with ancho pepper honey. Try today!"
        metaKeywords="bacon recipe"
    >
        <meta property="og:image" content={imageLollipops} />
        <link rel="image_src" href={imageLollipops} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageLollipops} class='img-responsive' alt='Steak Cut Heritage Cured Bacon' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Steak Cut Heritage Cured Bacon Lollipops</h1>

                            <div class="recipe-content">
                                <p >For generations we&rsquo;ve used the same words to describe our bacon: hand-trimmed, naturally smoked and hearty. So, when the word &ldquo;lollipop&rdquo; was tossed around, there were a few skeptics in the smokehouse. After all, you don&rsquo;t hand-craft something for years without becoming a little resistant to change.&nbsp;
</p>
                                <p >But, everyone warmed up to the idea after they saw the recipe: slow cured thick-cut Wright&reg; Brand Bacon skewered and grilled to crispy tender perfection, then glazed with ancho pepper honey to give the rich umami a little sweet heat. Yes please, lollipops all around.
</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        30&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        30&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>12-15 lollipops</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Package</span> Wright&reg; Brand Applewood Smoked Bacon (24 ounces)
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Tablespoon</span> ground ancho pepper
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Cup</span> clover honey
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Tablespoon</span> prepared adobo sauce
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                <span class="uom"></span> sriracha seasoning to taste
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                &frac14; <span class="uom">Cup</span> sliced green onions
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Remove your bacon from it’s package still stacked together. With it stacked, slice 1" wide blocks, then skewer them with a wooden skewer, and place on a sheet pan with a baking rack.
                </p>
                            <p>
                                2. Next, heat your oven to 325°F and roast the bacon pops for 30 minutes, or until the fat has rendered. While cooking the bacon, start heating the honey in a small sauce pan and stir in the adobo and ancho pepper. Heat on medium low, and hold at this temp for five minutes. Next give the bacon a generous coat of the adobo honey (like you would baste a turkey) until the outside is sticky and sweet. Remove the bacon from the pan and place on a clean plate, then chill the bacon for 20 minutes in the refrigerator.
                </p>
                            <p>
                                3. Finally, heat your outdoor grill to medium high and grill the bacon for 5-10 minutes or until peak crispiness is achieved. Remember to baste the bacon with the adobo honey while on the grill. Remove the delicious lollipops from the grill and finish them off with sriracha seasoning and green onions.
                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
